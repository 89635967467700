import React, { useState } from 'react';
import Styles from './styles/FrontPage.module.css';
import cameraIcon from '../images/camera-icon.png';
import axios from 'axios';
import AWS, { S3 } from 'aws-sdk';

interface FrontPageProps {
    idToken: string;
}

const rekogniseUrl = 'https://2tg0x2u3pf.execute-api.ap-southeast-2.amazonaws.com/rekognise-image';

const loginToS3 = (idToken: string) => {
    console.log("Logging in...");
    AWS.config.update({
        region: 'ap-southeast-2',
        credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'ap-southeast-2:0b337502-91fc-4a13-9cfc-1df977a8444c',
            Logins: {
                'cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_v6ciNvLzx': idToken
            }
        })
    });
    console.log("Generating S3 object...");
    return new AWS.S3({
        apiVersion: '2006-03-01',
        params: {Bucket: 'rekognise-images'}
     });
};

const uploadImageToS3 = (
    idToken: string, 
    file: File, 
    s3: S3, 
    setStuff: (str: string) => void, 
    isCoffeeClassifier: boolean
) => {
    console.log("Uploading image to s3...");
    s3.putObject({
        Body: file,
        Bucket: 'rekognise-images',
        Key: file.name
    }, (err, data) => {
        if (err) console.log(err, err.stack);
        else {
            console.log(data);
            rekogniseImage(idToken, file.name, setStuff, isCoffeeClassifier);
        }
    })
}

const rekogniseImage = (
    idToken: string, 
    imageKey: string, 
    setStuff: (str: string) => void,
    isCoffeeClassifier: boolean
) => {
    axios.post(rekogniseUrl, { "imageKey": imageKey }, {
        headers: {
            'authorization': `${idToken}`
        }
    }).then((response) => {
        setCaption(setStuff, response.data.body, isCoffeeClassifier)
        if(response.data.body) {
            setStuff(response.data.body[0]);
        } else {
            console.log("Body not found");
            console.log(response.data);
        }
    });
};

const setCaption = (
    setStuff: (str: string) => void, 
    itemList: string[],
    isCoffeeClassifier: boolean
) => {
    if (isCoffeeClassifier) {
        if (itemList.includes("Coffee Cup"))
            setStuff("THIS IS A COFFEE CUP");
        else
            setStuff("THIS IS NOT A COFFEE CUP");
    } else {
        let i: number = Math.floor(Math.random() * itemList.length);
        setStuff("This is a " + itemList[i]);
    }
}

export const FrontPage: React.FC<FrontPageProps> = ({ idToken }) => {

    const [source, setSource] = useState("");
    const [stuffIs, setStuff] = useState("");
    const [isCoffeeClassifier, setCoffeeClassifier] = useState(true);
    
    const handleCapture = async (target: any) => {
        console.log(`TOKEN IS: ${idToken}`);
        if (target.files) {
            if (target.files.length !== 0) {
                const file = target.files[0];
                const newUrl = URL.createObjectURL(file);
                setSource(newUrl);
                const s3 = loginToS3(idToken);
                uploadImageToS3(idToken, file, s3, setStuff, isCoffeeClassifier);
            };
        };
    };



    const imgVisiblityStyle = source === "" ? Styles.invisible : Styles.visible;

    return (
        <div className={Styles.frontPageContainer}>
            <div className={Styles.imgBox}>
                <img src={source} alt={"snap"} className={`${Styles.userImg} ${imgVisiblityStyle}`} />
            </div>
            <input
                accept="image/*"
                className={Styles.imgInput}
                id="camera-icon-button"
                type="file"
                capture="environment"
                onChange= { (e) => handleCapture(e.target)}
            />
            <div className={Styles.iconContainer} >
                <label htmlFor="camera-icon-button">
                    <img src={cameraIcon} className={Styles.cameraIcon} alt="Upload"/>
                </label>
            </div>
            <div className={Styles.descriptionContainer}>
                <p onClick={() => setCoffeeClassifier(!isCoffeeClassifier)}>
                    {stuffIs}
                </p>
            </div>

        </div>
    )
}