import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { GradnerSite } from './pages/GradnerSite';

ReactDOM.render(
    <GradnerSite />, 
    document.getElementById('root')
);

serviceWorker.register();
