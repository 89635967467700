import React from 'react';
import Styles from './styles/Header.module.css';
import {
    AuthenticationDetails,
    CognitoUserPool,
    CognitoUser
} from 'amazon-cognito-identity-js';

type setIdTokenType = (token: string) => void;

interface HeaderProps {
    setIdToken: setIdTokenType;
}

const login = (setIdToken: setIdTokenType) => {
    let authDetails = new AuthenticationDetails({
        Username: 'infinitemoto@hotmail.com',
        Password: 'Password01!'
    });
    
    let userPool = new CognitoUserPool({
        UserPoolId: 'ap-southeast-2_v6ciNvLzx',
        ClientId: '1gu4uu69seefc51hlm5v91luf9'
    });
    
    var cognitoUser = new CognitoUser({
        Username: 'infinitemoto@hotmail.com',
        Pool: userPool
    });

    cognitoUser.authenticateUser(authDetails, {
        onSuccess: (result) => {
            console.log("GOT TOKENSSS! :)");
            setIdToken(result.getIdToken().getJwtToken());
        },
        onFailure: (err) => {
            console.log("DONT GOT TOKENSSS :(")
            alert(err.message || JSON.stringify(err));
        }
    })
}

export const Header: React.FC<HeaderProps> = ({setIdToken}) => {
    return (
        <div className={Styles.headerContainer}>
            <h1 className={Styles.headerTitle}>
                gradner.net
            </h1>
            <p 
                className={Styles.headerSubtitle}
                onClick={() => login(setIdToken)}
            >
                a site for people
            </p>
        </div>
    )
}