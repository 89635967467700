import React from 'react';
import Styles from './styles/Footer.module.css';

export const Footer: React.FC = () => {
    return (
        <div className={Styles.footerContainer}>
            <p className={Styles.footerText}>
                gradner.net
            </p>
        </div>
    )
}