import React, { useState } from 'react';
import { Footer } from '../components/Footer';
import { Body } from '../components/Body';
import { Header } from '../components/Header';
import Styles from './styles/GradnerSite.module.css';

export const GradnerSite: React.FC = () => {   

    const [idToken, setIdToken] = useState('');

    return (
        <div className={Styles.pageContainer}>
            <Header setIdToken={setIdToken}/>
            <Body idToken={idToken}/>
            <Footer />
        </div>
    );
}