import React from 'react';
import { FrontPage } from '../components/FrontPage';
import Styles from './styles/Body.module.css';

interface BodyProps {
    idToken: string;
}

export const Body: React.FC<BodyProps> = ({ idToken }) => {
    return (
        <div className={Styles.bodyContainer}>
            <FrontPage idToken={idToken}/>
        </div>
    );
}